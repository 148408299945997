<template>
  <v-sheet color="white"  class="fill-height d-flex justify-center " :class="!SM ? 'align-center' : null">
    <v-row
        v-if="!displayPricing"
        class="no-gutters fill-height" >
      <v-col v-if="!SM" cols="12" md="6">
        <v-sheet :color="wsLIGHTCARD" class="fill-height d-flex align-center justify-center">
          <v-img
              src="/library/img/home/login.png"
              alt="WeStudy Login"
              max-height="500"
              max-width="500"
          />
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6" style="background : #ffffff">
        <div class="fill-height d-flex align-center justify-center"  >
          <v-sheet  :width="SM ? 300 : 350">
            <auth-window-new
                v-if="!$store.state.auth.loggedIn"
                no-auth-action
                is-new-business
            />
            <register-business-general
                v-else
                @next="openPricing"
            />

          </v-sheet>
        </div>

      </v-col>
    </v-row>
    <v-sheet   v-else :width="SM ? '290' : null" class="pt-6" >
      <home-section-payment-plans
          style="width: 100%"
          title="homepage.register.choose_plan_to_proceed"
          register
          @select="addBusiness"
      />
    </v-sheet>

  </v-sheet>
</template>

<script>
import authWindowNew from "@/components/auth/authWindowNew";
import registerBusinessGeneral from "@/components/pages/homepage_westudy/register/registerBusinessGeneral";
import homeSectionPaymentPlans from "@/components/pages/homepage_westudy/home/sections/homeSectionPaymentPlans";
import {mapActions} from "vuex";
export default {
  name: "HomepageRegister",
  components : {
    authWindowNew,
    registerBusinessGeneral,
    homeSectionPaymentPlans
  },
  data() {
    return {
      entityData : {},
      displayPricing : false,
    }
  },
  methods : {
    ...mapActions('business',  [
      'ADD_BUSINESS' ,
      'GET_CLIENT_PLANS'
    ]) ,
    ...mapActions('auth',  [ 'GET_USER' ]),

    async addBusiness(paymentPlanId) {

      this.entityData.business_plan = paymentPlanId
      this.loading = true
      this.entityData.registration_device = this.DEVICE
      let result = await this.ADD_BUSINESS(this.entityData)
      if ( !result ) { return false }
      await this.GET_USER();

      this.$store.state.business.selectedBusiness.alias = this.entityData.alias
      this.$store.state.business.selectedBusiness.name = result.name
      await this.$router.push(this.businessDashLink())

    },
    openPricing(data) {
      this.displayPricing = true
      this.entityData = this.COPY(data)
    },
    authFinished() {
      this.notify('AuthFinished')
    }
  },
  beforeMount() {
    this.$emit('hideFooter' , true)
  },
  beforeDestroy() {
    this.$emit('hideFooter' , false)
  }
}
</script>

<style scoped>

</style>